<template>
    <div id="app">

        <!--<link rel="icon" href="https://www.comuniverse.de/wp-content/uploads/2020/03/cropped-cropped-comuniverse-1-512x512-1-32x32.png" sizes="32x32" />
        <link rel="icon" href="https://www.comuniverse.de/wp-content/uploads/2020/03/cropped-cropped-comuniverse-1-512x512-1-192x192.png" sizes="192x192" />
        <link rel="apple-touch-icon" href="https://www.comuniverse.de/wp-content/uploads/2020/03/cropped-cropped-comuniverse-1-512x512-1-180x180.png" />
        <meta name="msapplication-TileImage" content="https://www.comuniverse.de/wp-content/uploads/2020/03/cropped-cropped-comuniverse-1-512x512-1-270x270.png" />-->

        <main>
            <Sidebar/>

            <div class="datafeed">
                <div class="datafeed-header">
                    <h1 class="datafeed-header__title">CONFLEET</h1>
                    <router-link to="/user" class="user-button" v-if="authUser">
                        <span class="nav-item">{{ authUser.name }}</span>
                        <PersonFillIcon/>
                    </router-link>
                    <a href="#" class="logout-button" v-if="authUser" v-on:click.prevent="logout()">
                        <span class="nav-item">Logout</span>
                        <DoorOpenIcon/>
                        <DoorClosedIcon/>
                    </a>
                    <router-link v-else to="/login" class="login-button">
                        <span class="nav-item">Login</span>
                        <DoorOpenIcon/>
                        <DoorClosedIcon/>
                    </router-link>
                </div>

                <router-view/>

                <Footer/>
            </div>
        </main>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Sidebar from "@/components/Sidebar";
    import Footer from "@/components/Footer";
    import DoorOpenIcon from "@/components/icons/DoorOpenIcon";
    import DoorClosedIcon from "@/components/icons/DoorClosedIcon";
    import PersonFillIcon from "@/components/icons/PersonFillIcon";

    export default {
        name: "app",
        data() {
            return {
                loggingOut: false,
                error: false
            };
        },
        components: {
            PersonFillIcon,
            Sidebar,
            Footer,
            DoorOpenIcon,
            DoorClosedIcon,
        },
        computed: {
            ...mapGetters("auth", ["authUser", "isAdmin", "loading", "error"]),
        },
        methods: {
            logout() {
                this.$store.dispatch("auth/logout");
            },
        },
    };
</script>

<style lang="scss">
    main {
        display: flex;
        height: 100vh;
        overflow: hidden;
    }

    .datafeed {
        flex: 1;
        padding: $outer-padding;
        display: flex;
        flex-flow: column;
    }

    .datafeed-header {
        height: $topbar-height;
        padding-bottom: $topbar-padding-bottom;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-bottom: $default-border;

        &__title {
            margin-right: auto;
        }

        a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-decoration: none;
            color: $black-text;

            &:hover {
                color: $primary-color;
            }

            &.logout-button {
                .bi-door-closed {
                    display: none;
                }
            }

            &.logout-button:hover {
                .bi-door-open {
                    display: none;
                }

                .bi-door-closed {
                    display: block;
                }
            }

            &.login-button {
                .bi-door-open {
                    display: none;
                }
            }

            &.login-button:hover {
                .bi-door-open {
                    display: block;
                }

                .bi-door-closed {
                    display: none;
                }
            }

            .nav-item {
                margin: 0 10px 0 20px;
                font-size: $font-size-big;
            }
        }
    }
</style>
