<template>
    <div class="sidebar" :class="{'sidebar--closed': !open}">
        <div class="sidebar-topbox">
            <div class="sidebar-topbox-img">
                <router-link to="/dashboard" v-if="open">
                    <img src="https://confleet.de/view/assets/img/logo.png" width="190" height="36"
                         alt="comuniverse Data Hub">
                </router-link>
                <div class="action-icon" v-on:click.prevent="toggle()">
                    <ArrowBarLeftIcon v-if="open"/>
                    <ArrowBarRightIcon v-else/>
                </div>
            </div>
            <div class="sidebar-topbox-nav">
                <ul>
                    <li>
                        <router-link to="/">
                            <HouseIcon/>
                            <span class="nav-item" v-if="open">Startseite</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/dashboard">
                            <Snow3Icon/>
                            <span class="nav-item" v-if="open">Temperaturdaten</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/users">
                            <PeopleFillIcon/>
                            <span class="nav-item" v-if="open">Users</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="sidebar-bottombox">
            <div class="sidebar-bottombox-nav">
                <a href="#">
                    <GearIcon/>
                    <span class="nav-item" v-if="open">Einstellungen</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import PeopleFillIcon from "@/components/icons/PeopleFillIcon";
    import HouseIcon from "@/components/icons/HouseIcon";
    import GearIcon from "@/components/icons/GearIcon";
    import Snow3Icon from "@/components/icons/Snow3Icon";
    import ArrowBarLeftIcon from "@/components/icons/ArrowBarLeftIcon";
    import ArrowBarRightIcon from "@/components/icons/ArrowBarRightIcon";

    export default {
        data() {
            return {
                open: true
            };
        },
        components: {
            PeopleFillIcon,
            HouseIcon,
            GearIcon,
            Snow3Icon,
            ArrowBarLeftIcon,
            ArrowBarRightIcon,
        },
        methods: {
            toggle: function () {
                this.open = !this.open;
            },
        },
        name: "Sidebar",
    };
</script>

<style lang="scss">
    .sidebar {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        width: 20%;
        padding: $outer-padding;
        background: $very-light-grey;
        box-shadow: $main-shadow;

        &--closed {
            width: 90px;
        }

        .sidebar-topbox {
            .sidebar-topbox-img {
                width: 100%;
                height: $topbar-height;
                padding-bottom: $topbar-padding-bottom;
                display: flex;
                justify-content: space-between;
                align-items: center;

                svg {
                    &:hover {
                        cursor: pointer;
                        color: $primary-color;
                    }
                }
            }

            .sidebar-topbox-nav {
                padding: 15px 0;
                border-top: $default-border;
                border-bottom: $default-border;

                ul {
                    li {
                        padding: 10px 0;

                        a {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            text-decoration: none;
                            color: $black-text;

                            .nav-item {
                                margin-left: 15px;
                                font-size: $font-size-big;
                            }

                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }

        .sidebar-bottombox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: $bottombar-height;
            padding-top: $bottombar-padding-top;
            border-top: $default-border;

            .sidebar-bottombox-nav {
                width: 100%;

                a {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-decoration: none;
                    color: $black-text;

                    .nav-item {
                        margin-left: 15px;
                        font-size: $font-size-big;
                    }

                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
    }
</style>
