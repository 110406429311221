import {getError} from "@/utils/helpers";
import TemperatureService from "@/services/TemperatureService";

export const namespaced = true;

export const state = {
    temperatures: {},
    loading: false,
    error: null,
};

export const mutations = {
    SET_DATA(state, data) {
        const vehicle = data.vehicle.toString();

        let transformed = {};
        transformed[vehicle] = [];

        for (let i = 0; i < data.data.length; i++) {
            let entry = data.data[i].slice(0);
            entry[0] = new Date(entry[0] * 1000);
            transformed[vehicle].push(entry);
        }

        state.temperatures = Object.assign({}, state.temperatures, transformed);
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
};

export const actions = {
    getData({commit}, opts) {
        commit("SET_LOADING", true);
        TemperatureService.getData(opts.tags, opts.min, opts.max)
            .then((response) => {
                commit("SET_DATA", {
                    vehicle: opts.vehicle,
                    data: response.data
                });
                commit("SET_LOADING", false);
                commit("SET_ERROR", null);
            })
            .catch((error) => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    },
};

export const getters = {
    temperatures: (state) => {
        return state.temperatures;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};
