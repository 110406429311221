import {getError} from "@/utils/helpers";
import SensorService from "@/services/SensorService";

export const namespaced = true;

export const state = {
    sensors: {},
    loading: false,
    error: null,
};

export const mutations = {
    SET_DATA(state, data) {
        state.sensors = Object.assign({}, state.sensors, data);
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
};

export const actions = {
    getData({commit}, opts) {
        commit("SET_LOADING", true);
        SensorService.getData(opts.current, opts.children)
            .then((response) => {
                commit("SET_DATA", response.data);
                commit("SET_LOADING", false);
                commit("SET_ERROR", null);
            })
            .catch((error) => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    },
};

export const getters = {
    sensors: (state) => {
        return state.sensors;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};
