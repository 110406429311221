export const getError = (error) => {
    const errorMessage = "API Error, please try again.";

    if (error.name === "Fetch User") {
        return error.message;
    }

    if (
        typeof error.response.data.exception !== 'undefined'
        && error.response.data.exception === 'Swift_TransportException'
    ) {
        return 'Your Account has been created.';
    }

    if (!error.response) {
        console.error(`API ${error.config.url} not found`);
        return errorMessage;
    }

    if (process.env.NODE_ENV === "development") {
        console.error(error.response);
    }

    if (error.response.data && error.response.data.errors) {
        return error.response.data.errors;
    }

    return errorMessage;
};
