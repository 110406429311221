import {getError} from "@/utils/helpers";
import VehicleService from "@/services/VehicleService";

export const namespaced = true;

export const state = {
    vehicles: {},
    current: false,
    loading: false,
    error: null,
};

export const mutations = {
    SET_DATA(state, data) {
        state.vehicles = data;
    },
    SET_CURRENT(state, data) {
        state.current = data;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
};

export const actions = {
    getData({commit}) {
        commit("SET_LOADING", true);
        VehicleService.getData()
            .then((response) => {
                commit("SET_DATA", response.data);
                commit("SET_LOADING", false);
                commit("SET_ERROR", null);
            })
            .catch((error) => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    },
    setCurrent({commit}, data) {
        commit("SET_CURRENT", data);
    },
};

export const getters = {
    vehicles: (state) => {
        return state.vehicles;
    },
    current: (state) => {
        return state.current ? state.current.toString() : state.current;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};
