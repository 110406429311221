import * as API from "@/services/API";

export default {
    getData(current, children) {
        let vehicles = current;

        if (typeof children === 'object' && children.length) {
            vehicles += '%2C' + children.map(function (child) {
                return child.id;
            }).join('%2C');
        }

        return API.apiClient.get('/sensor?vehicles=' + vehicles);
    },
};
