import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/normalize.css";
import "@/assets/js/dygraph.synchronizer.js";
import "@/assets/css/dygraph.min.css";
import "@/assets/css/style.scss";

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
