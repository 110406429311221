import Vue from "vue";
import store from "@/store/index";
import VueRouter from "vue-router";
import auth from "@/middleware/auth";
import admin from "@/middleware/admin";
import guest from "@/middleware/guest";
import middlewarePipeline from "@/router/middlewarePipeline";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        meta: {
            title: process.env.VUE_APP_META_TITLE,
            middleware: [guest],
        },
        component: () => import(/* webpackChunkName: "home" */ "../views/Home"),
    },
    {
        path: "/dashboard",
        name: "dashboard",
        meta: {
            title: process.env.VUE_APP_META_TITLE,
            middleware: [auth],
        },
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "../views/Dashboard"),
    },
    {
        path: "/user",
        name: "user",
        meta: {
            title: process.env.VUE_APP_META_TITLE,
            middleware: [auth],
        },
        component: () => import(/* webpackChunkName: "user" */ "../views/User"),
    },
    {
        path: "/users",
        name: "users",
        meta: {
            title: process.env.VUE_APP_META_TITLE,
            middleware: [auth, admin],
        },
        component: () => import(/* webpackChunkName: "users" */ "../views/Users"),
    },
    {
        path: "/login",
        name: "login",
        meta: {
            title: process.env.VUE_APP_META_TITLE,
            middleware: [guest],
        },
        component: () => import(/* webpackChunkName: "login" */ "../views/Login"),
    },
    {
        path: "/register",
        name: "register",
        meta: {
            title: process.env.VUE_APP_META_TITLE,
            middleware: [guest],
        },
        component: () =>
            import(/* webpackChunkName: "register" */ "../views/Register"),
    },
    {
        path: "/reset-password",
        name: "resetPassword",
        meta: {
            title: process.env.VUE_APP_META_TITLE,
            middleware: [guest],
        },
        component: () =>
            import(/* webpackChunkName: "reset-password" */ "../views/ResetPassword"),
    },
    {
        path: "/forgot-password",
        name: "forgotPassword",
        meta: {
            title: process.env.VUE_APP_META_TITLE,
            middleware: [guest],
        },
        component: () =>
            import(
                /* webpackChunkName: "forgot-password" */ "../views/ForgotPassword"),
    },
    {
        path: "/:catchAll(.*)",
        name: "notFound",
        meta: {
            title: process.env.VUE_APP_META_TITLE,
        },
        component: () =>
            import(/* webpackChunkName: "not-found" */ "../views/NotFound"),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
});

router.beforeEach((to, from, next) => {
    const middleware = to.meta.middleware;
    const context = {to, from, next, store};

    if (!middleware) {
        return next();
    }

    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }

    next();
});

export default router;
