<template>
    <footer class="datafeed-footer">
        &copy;{{ new Date().getFullYear() }} comuniverse GmbH
        <a href="https://www.comuniverse.de/datenschutzerklaerung/" class="base-link" target="_blank">Datenschutz</a>
        <a href="https://www.comuniverse.de/impressum/" class="base-link" target="_blank">Impressum</a>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
    };
</script>

<style lang="scss">
    .datafeed-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: $bottombar-height;
        padding-top: $bottombar-padding-top;
        border-top: $default-border;
        margin-top: auto;

        a {
            color: $black-text;
            text-decoration: none;
            padding-left: 10px;

            &:before {
                content: "-";
                margin-right: 10px;
            }

            &:hover {
                color: $primary-color;

                &:before {
                    color: $black-text;
                }
            }
        }
    }
</style>
